import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { AppService } from '../shared/app-service.service';

@Component({
  selector: 'app-blogs',
  templateUrl: './blogs.component.html',
  styleUrls: ['./blogs.component.scss']
})
export class BlogsComponent implements OnInit {

  blogs:any = [];
  imageBasePath = environment.imageBasePath;
  constructor(private appService:AppService) { }

  ngOnInit(): void {
    this.appService.getblogs().subscribe((res:any)=>{
      this.blogs = res;
      
    })
  }

}
