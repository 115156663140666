import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { environment } from 'src/environments/environment';
import { AppService } from '../shared/app-service.service';
declare var window: any;
@Component({
  selector: 'app-booking',
  templateUrl: './booking.component.html',
  styleUrls: ['./booking.component.scss']
})
export class BookingComponent implements OnInit {

  hotel: any = [];
  hotelId: any = '';
  isBookingDone = false;
  imageBasePath = environment.imageBasePath;
  bookingId = "";
  pageData = {
    bookingDate: "",
    firstName: "",
    email: "",
    lastName: "",
    phone: "",
    slotId: ""
  }
  pageType = '';
  currentDate = new Date().toISOString().substr(0, 10);
  appId = '{APPLICATION_ID}';
  locationId = '{LOCATION_ID}';
  constructor(private _AppService: AppService, private route: ActivatedRoute) {
    this.route.params.subscribe(params => {
      this.hotelId = params['hotelid'];
    });

  }

  ngOnInit(): void {
    this.getHotel();
    this.pageType = window.location.pathname.search("tourBooking") != -1 ? 'TourBooking' : "VenueBooking";
  }

  getHotel() {
    this._AppService.gethotelById(this.hotelId).subscribe((res: any) => {
      this.hotel = res;
    })
  }

  requestBooking() {
    let data = {
      bookingTypeId: this.hotelId,
      bookingDate: this.pageData.bookingDate,
      isActive: 1,
      status: "Pending",
      firstName: this.pageData.firstName,
      lastName: this.pageData.lastName,
      email: this.pageData.email,
      phone: this.pageData.phone,
      bookingType: 'hotel',
      slotId: this.pageData.slotId
    }
    this._AppService.postBooking(data).subscribe((res: any) => {
      this.bookingId = res.bookingId;
      this.isBookingDone = true;
    });

  }
}
