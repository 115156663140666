import { Component, OnInit } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { environment } from 'src/environments/environment';
import { AppService } from '../shared/app-service.service';

@Component({
  selector: 'app-match-details',
  templateUrl: './match-details.component.html',
  styleUrls: ['./match-details.component.scss']
})
export class MatchDetailsComponent implements OnInit {

  match:any = [];
  matchId ='';
  imageBasePath = environment.imageBasePath;
  constructor(private _AppService:AppService,private route: ActivatedRoute,private toastrService:ToastrService,private meta: Meta) { 
    this.route.params.subscribe(params => {
      this.matchId = params['matchid'];
    })
  }

  ngOnInit(): void {
    this._AppService.getContestDetails(this.matchId).subscribe((res:any)=>{
      this.match = res;
      this.meta.addTags([ 
          { name: 'description', content: this.match.description }, 
          { name: 'title', content: this.match.name },
          { name: 'og:url', content:`https://vbogs.com/match-details/${this.matchId}` },
          { name: 'og:type', content: 'article' },
          { property: 'og:image', content: this.imageBasePath+this.match.image },
          { name: 'twitter:image', content: this.imageBasePath+this.match.image } 
      ]);
    });

  }

}
