<div class="vbogs-content-wrapper vbogs-clearfix vbogs-content-wrapper-full-width-unboxed">
    <!-- BEGIN .vbogs-main-content -->
    <div class="vbogs-main-content vbogs-main-content-full-width-unboxed">
      <div class="vc_row wpb_row vc_row-fluid vbogs-slideshow-wrapper-1 vc_custom_1626770139067 vc_row-has-fill">
        <div class="wpb_column vc_column_container vc_col-sm-12 vc_col-has-fill">
          <div class="vc_column-inner vc_custom_1626769422811">
            <div class="wpb_wrapper">
              <div class="flexslider vbogs-slider-fullscreen">
                <ul class="slides">
                  <li
                  *ngFor="let banner of bannersData"
                    style="height: calc(100vh - 220px); width: 100%; float: left; margin-right: -100%; position: relative; opacity: 1; display: block; z-index: 2;"
                    class="flex-active-slide">
                    <div class="vbogs-slider-caption-1">
                      <h2>{{banner.bannerTitle}}</h2><a
                        [href]="banner.bannerLink"
                        class="vbogs-slider-button">{{banner.bannerLinkTitle}}</a>
                    </div>
                    <div class="vbogs-slider-overlay" style="background:#000;opacity:0.5"></div>
                    <div class="vbogs-slide-image"
                    [ngStyle]="{'background-image':'url('+imageBasePath+banner.image+')'}">
                    </div>
                  </li>
                  
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="vc_row wpb_row vc_row-fluid vc_custom_1625649513890 vc_row-has-fill">
        <div class="wpb_column vc_column_container vc_col-sm-12 vc_col-has-fill">
          <div class="vc_column-inner vc_custom_1625649524968">
            <div class="wpb_wrapper">
              <h3 class="vbogs-title-35px vbogs-clearfix vbogs-title-center">{{pageData.pageTitle}}</h3>
              <div class="wpb_text_column wpb_content_element  vbogs-section-wrapper-1">
                <div class="wpb_wrapper">
                  <p style="text-align: center;" [innerHTML]="pageData.description"></p>
                </div>
              </div>
              <div class="vbogs-clearboth"></div>
            </div>
          </div>
        </div>
      </div>
      <div style="display: none;" class="vc_row wpb_row vc_row-fluid vc_custom_1625649537047 vc_row-has-fill">
        <div class="wpb_column vc_column_container vc_col-sm-12 vc_col-has-fill">
          <div class="vc_column-inner vc_custom_1610002239003">
            <div class="wpb_wrapper">
              <section class="vbogs-image-text-wrapper vbogs-clearfix vbogs-image-text-wrapper-right-align">
                <div class="vbogs-it-image-wrapper"
                  style="background-image:url(../assets/images/01-3.jpg);">
                  <img src="../assets/images/01-3.jpg" alt=""></div>
                <div class="vbogs-it-text-wrapper" >
                  <h3 class="vbogs-title-28px vbogs-clearfix vbogs-title-left" >About Us
                  </h3>
                  <p>Maecenas feugiat mattis ipsum, vitae semper massa porttitor sit amet. Nulla
                    mattis, urna et posuere ornare, neque leo dapibus ante, nec dignissim massa felis ad nulla donec
                    porttitor nulla et tristique dignissim. </p><a
                    href="/aboutus" class="vbogs-button1">Learn More</a>
                </div>
              </section>
            </div>
          </div>
        </div>
      </div>
      <div class="vc_row wpb_row vc_row-fluid vc_custom_1625643442280 vc_row-has-fill">
        <div class="wpb_column vc_column_container vc_col-sm-12 vc_col-has-fill">
          <div class="vc_column-inner vc_custom_1625647823481">
            <div class="wpb_wrapper">
              <div class="vc_row wpb_row vc_inner vc_row-fluid vc_custom_1625647793106 vc_row-has-fill">
                <div class="wpb_column vc_column_container vc_col-sm-12 vc_col-has-fill">
                  <div class="vc_column-inner vc_custom_1627546014696">
                    <div class="wpb_wrapper">
                      <h3 class="vbogs-title-28px vbogs-clearfix vbogs-title-center">Our Resorts</h3>
                      <div class="wpb_text_column wpb_content_element  vbogs-section-wrapper-1">
                        <div class="wpb_wrapper">
                          <p style="text-align: center;"><span style="color: #656a70;">Maecenas feugiat mattis ipsum,
                              vitae semper massa porttitor sit amet. Nulla mattis, urna et posuere ornare, neque leo
                              dapibus ante, nec dignissim.</span></p>

                        </div>
                      </div>

                      <!-- BEGIN .shb-accommodation-listing-style-1 -->
                      <div
                        class="shb-accommodation-listing-style-1 shb-accommodation-listing-style-1-columns-3 vbogs-owl-carousel-3 owl-carousel owl-theme owl-loaded">
                        <div class="item" *ngFor="let hotel of hotels">
                          <app-room [hotel]="hotel"></app-room>
                        </div>
                      </div>

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <app-hotel-facilities></app-hotel-facilities>
      <app-special-offers></app-special-offers>
      <!-- END .vbogs-main-content -->
    </div>
    <!-- END .vbogs-content-wrapper -->
  </div>