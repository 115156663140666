import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AboutusComponent } from './aboutus/aboutus.component';
import { BlogDetailsComponent } from './blog-details/blog-details.component';
import { BlogsComponent } from './blogs/blogs.component';
import { BookingHistoryComponent } from './booking-history/booking-history.component';
import { BookingComponent } from './booking/booking.component';
import { ContactusComponent } from './contactus/contactus.component';
import { EventDetailsComponent } from './event-details/event-details.component';
import { EventsComponent } from './events/events.component';
import { GroundBookingComponent } from './ground-booking/ground-booking.component';
import { GroundDetailsComponent } from './ground-details/ground-details.component';
import { GroundsComponent } from './grounds/grounds.component';
import { GuestLoginComponent } from './guest-login/guest-login.component';
import { HomeComponent } from './home/home.component';
import { HotelDetailsComponent } from './hotel-details/hotel-details.component';
import { HotelsComponent } from './hotels/hotels.component';
import { MatchDetailsComponent } from './match-details/match-details.component';
import { PaymentSuccessComponent } from './payment-success/payment-success.component';
import { SeriesDetailsComponent } from './series-details/series-details.component';
import { SeriesComponent } from './series/series.component';

const routes: Routes = [
  { path: 'home', component: HomeComponent},
  { path: 'aboutus', component: AboutusComponent},
  { path: 'contactus', component: ContactusComponent},
  { path: 'venues', component: HotelsComponent},
  { path: 'venues/:hotelid/:hotelUrl', component: HotelDetailsComponent},
  { path: 'events', component: EventsComponent},
  { path: 'events/:eventid', component: EventDetailsComponent},
  { path: 'booking/:hotelid', component: BookingComponent},
  { path: 'tourBooking/:hotelid', component: BookingComponent},
  { path: 'booking-history', component: BookingHistoryComponent},
  { path: 'payment-success', component: PaymentSuccessComponent},
  { path: 'guest-login', component: GuestLoginComponent},
  { path: 'grounds', component: GroundsComponent},
  { path: 'ground-details/:groundid/:groundUrl', component: GroundDetailsComponent},
  { path: 'ground-booking/:groundid', component: GroundBookingComponent},
  { path: 'blogs', component: BlogsComponent},
  { path: 'blog-details/:blogid', component: BlogDetailsComponent},
  { path: 'series', component: SeriesComponent},
  { path: 'series-details/:serieid', component: SeriesDetailsComponent},
  { path: 'match-details/:matchid', component: MatchDetailsComponent},
  { path: '', redirectTo: 'home', pathMatch: 'full' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
