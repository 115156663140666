import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { AppService } from '../app-service.service';
declare var $: any;
@Component({
  selector: 'app-special-offers',
  templateUrl: './special-offers.component.html',
  styleUrls: ['./special-offers.component.scss']
})
export class SpecialOffersComponent implements OnInit {
  specialOffersList:any = [];
  imageBasePath = environment.imageBasePath;
  constructor(private appService:AppService) { }

  ngOnInit(): void {

    this.appService.getspecialoffersList().subscribe((res:any)=>{
      this.specialOffersList = res;
      setTimeout(()=>{
        $('.vbogs-owl-carousel-5').owlCarousel({
          loop: false,
          margin: 30,
          nav: false,
          pagination: true,
          navText: "",
          autoHeight: false,
          responsive: {
            0: {
              items: 1
            },
            490: {
              items: 2
            },
            710: {
              items: 3
            },
          }
        });
      },100)
    })
   
  }

}
