<div class="vbogs-page-header"
    style="background-image:url('../../assets/images/08-6.jpg');">
    <h1>Contact Us</h1>
    <!-- END .vbogs-page-header -->
</div>
<div class="vbogs-content-wrapper vbogs-clearfix vbogs-content-wrapper-full-width">
    <!-- BEGIN .vbogs-main-content -->
    <div class="vbogs-main-content vbogs-main-content-full-width">

        <div class="vc_row wpb_row vc_row-fluid">
            <div class="wpb_column vc_column_container vc_col-sm-8">
                <div class="vc_column-inner">
                    <div class="wpb_wrapper">
                        <div role="form" class="wpcf7" id="wpcf7-f2080-p2096-o1" lang="en-US" dir="ltr">
                            <div class="screen-reader-response">
                                <p role="status" aria-live="polite" aria-atomic="true"></p>
                                <ul></ul>
                            </div>
                            <form action="/sohohotel/demo1/contact-us-ii/#wpcf7-f2080-p2096-o1" method="post"
                                class="wpcf7-form init" novalidate="novalidate" data-status="init">
                                <div style="display: none;">
                                    <input type="hidden" name="_wpcf7" value="2080">
                                    <input type="hidden" name="_wpcf7_version" value="5.5.2">
                                    <input type="hidden" name="_wpcf7_locale" value="en_US">
                                    <input type="hidden" name="_wpcf7_unit_tag" value="wpcf7-f2080-p2096-o1">
                                    <input type="hidden" name="_wpcf7_container_post" value="2096">
                                    <input type="hidden" name="_wpcf7_posted_data_hash" value="">
                                </div>
                                <p><label> Your name<br>
                                        <span class="wpcf7-form-control-wrap your-name"><input type="text"
                                                name="your-name" value="" size="40"
                                                class="wpcf7-form-control wpcf7-text wpcf7-validates-as-required"
                                                aria-required="true" aria-invalid="false"></span> </label></p>
                                <p><label> Your email<br>
                                        <span class="wpcf7-form-control-wrap your-email"><input type="email"
                                                name="your-email" value="" size="40"
                                                class="wpcf7-form-control wpcf7-text wpcf7-email wpcf7-validates-as-required wpcf7-validates-as-email"
                                                aria-required="true" aria-invalid="false"></span> </label></p>
                                <p><label> Subject<br>
                                        <span class="wpcf7-form-control-wrap your-subject"><input type="text"
                                                name="your-subject" value="" size="40"
                                                class="wpcf7-form-control wpcf7-text wpcf7-validates-as-required"
                                                aria-required="true" aria-invalid="false"></span> </label></p>
                                <p><label> Your message (optional)<br>
                                        <span class="wpcf7-form-control-wrap your-message"><textarea name="your-message"
                                                cols="40" rows="10" class="wpcf7-form-control wpcf7-textarea"
                                                aria-invalid="false"></textarea></span> </label></p>
                                <p><input type="submit" value="Submit"
                                        class="wpcf7-form-control has-spinner wpcf7-submit"><span
                                        class="wpcf7-spinner"></span></p>
                                <div class="wpcf7-response-output" aria-hidden="true"></div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <div class="wpb_column vc_column_container vc_col-sm-4">
                <div class="vc_column-inner">
                    <div class="wpb_wrapper">
                        <h3 class="vbogs-title-20px vbogs-clearfix vbogs-title-left">Get In Touch</h3>
                        <div class="wpb_text_column wpb_content_element ">
                            <div class="wpb_wrapper">
                                <p>Duis commodo ipsum quis ante venenatis rhoncus. Vivamus imperdiet felis ac facilisis
                                    hendrerit nulla eu elementum ex, ut pulvinar est nam aliquet et.</p>

                            </div>
                        </div>
                        <div class="vc_empty_space" style="height: 35px"><span class="vc_empty_space_inner"></span>
                        </div>
                        <h3 class="vbogs-title-20px vbogs-clearfix vbogs-title-left">Contact Details</h3>
                        <ul class="vbogs-contact-details-list">
                            <li class="vbogs-address clearfix">Vbogs Resorts Resort &amp; 7488 TX-24, Commerce, TX, United States, Texas</li>
                            <li class="vbogs-phone clearfix"><a href="tel:+441234567890">+1 952-838-5687</a></li>
                            <li class="vbogs-email clearfix"><a
                                    href="mailto:bookings@website.com">vj@vbogs.com</a></li>
                        </ul>
                        <div class="vc_empty_space" style="height: 70px"><span class="vc_empty_space_inner"></span>
                        </div>
                    </div>
                </div>
            </div>
        </div>



        <!-- END .vbogs-main-content -->
    </div>


    <!-- END .vbogs-content-wrapper -->
</div>