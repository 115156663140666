<div class="vbogs-page-header"
    style="background-image:url('../../assets/images/08-6.jpg');">
    <h1>Grounds</h1>
    <!-- END .vbogs-page-header -->
</div>
<div class="vbogs-content-wrapper vbogs-clearfix vbogs-content-wrapper-full-width">
    <!-- BEGIN .vbogs-main-content -->
    <div class="vbogs-main-content vbogs-main-content-full-width">
        <div class="vc_row wpb_row vc_row-fluid">
            <div class="wpb_column vc_column_container vc_col-sm-12">
                <div class="vc_column-inner">
                    <div class="wpb_wrapper">
                        <!-- BEGIN .vbogs-blog-wrapper-2 -->
                        <div class="vbogs-blog-wrapper-2 vbogs-blog-wrapper-2-3-col vbogs-clearfix">
                            <!-- BEGIN .vbogs-blog-block -->
                            <div *ngFor="let ground of grounds"
                                class="vbogs-blog-block vbogs-clearfix post-2113 post type-post status-publish format-standard has-post-thumbnail hentry category-city-breaks tag-accommodation">
                                <div class="vbogs-blog-block-image">
                                    <a rel="bookmark" href="/ground-details/{{ground.id}}/{{ground.url}}" title="{{ground.name}}">
                                        <img class="shb-accommodation-listing-image" [src]="imageBasePath+ground.image" alt="{{ground.name}}"> </a>
                                </div>
                                <!-- BEGIN .vbogs-blog-title-meta-wrapper -->
                                <div class="vbogs-blog-title-meta-wrapper">
                                    <h3><a rel="bookmark" href="/ground-details/{{ground.id}}/{{ground.url}}" title="{{ground.name}}">{{ground.name}}</a></h3>
                                </div>
                                <!-- END .vbogs-blog-block -->
                            </div>
                        </div>
                        <div class="vbogs-clearboth"></div>
                    </div>
                </div>
            </div>
        </div>
        <!-- END .vbogs-main-content -->
    </div>
    <!-- END .vbogs-content-wrapper -->
</div>