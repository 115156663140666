import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { AppService } from '../shared/app-service.service';

@Component({
  selector: 'app-events',
  templateUrl: './events.component.html',
  styleUrls: ['./events.component.scss']
})
export class EventsComponent implements OnInit {

  events:any = [];
  imageBasePath = environment.imageBasePath;
  constructor(private appService:AppService) { }

  ngOnInit(): void {
    this.appService.getevents().subscribe((res:any)=>{
      this.events = res;
    })
  }

}
