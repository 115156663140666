<div class="vbogs-content-wrapper vbogs-clearfix">
    <!-- BEGIN .vbogs-main-content -->
    <div class="vbogs-main-content vbogs-main-content-full-width vbogs-blog-single-page">
        <!-- BEGIN .vbogs-blog-single-wrapper -->
        <div class="vbogs-blog-single-wrapper">
            <!-- BEGIN .vbogs-blog-block -->
            <div *ngIf="blog.name"
                class="vbogs-blog-block post-2113 post type-post status-publish format-standard has-post-thumbnail hentry category-city-breaks tag-accommodation">
               
                <h3 class="shb-blog-single-title">{{blog.name}}</h3>
                <!-- BEGIN .vbogs-blog-meta -->
                <div class="vbogs-blog-meta clearfix">
                    <span class="vbogs-blog-meta-author"><i class="fas fa-calendar"></i><a
                            rel="bookmark" title="Celebrating 20th Anniversary Of Vbogs Resorts">{{blog.blogDate |date:'MMMM d, Y'}}</a></span>
                    <span class="vbogs-blog-meta-author"><i class="fas fa-pencil-alt"></i>By <a
                            title="Posts by admin2021" rel="author">{{blog.blogBy}}</a></span>
                    
                    <!-- END .vbogs-blog-meta -->
                </div>
                <div class="vbogs-blog-single-image">
                    <img [src]="imageBasePath+blog.image"
                        [alt]="blog.eventTitle">
                        <div>
                            <share-buttons theme="modern-dark"
                                [include]="[ 'facebook', 'twitter', 'linkedin', 'whatsapp']"
                                [showIcon]="true"
                                [showText]="true"
                                url="https://vbogs.com/blog-details/{{blogId}}"
                                description="{{blog.name}}"
                                image="{{imageBasePath+blog.thumbnail}}"
                                class="pt-5">
                            </share-buttons>
                        </div>
                </div>
                
                <!-- BEGIN .vbogs-blog-description -->
                <div class="vbogs-blog-description vbogs-clearfix">

                    <div class="vc_row wpb_row vc_row-fluid">
                        <div class="wpb_column vc_column_container vc_col-sm-12">
                            <div class="vc_column-inner">
                                <div class="wpb_wrapper">
                                    <div class="wpb_text_column wpb_content_element ">
                                        <div class="wpb_wrapper" [innerHTML]="blog.description"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- END .vbogs-blog-description -->
                </div>

                <!-- END .vbogs-blog-block -->
            </div>
        </div>
        <!-- END .vbogs-main-content -->
    </div>

    <!-- END .vbogs-content-wrapper -->
</div>