<div class="vc_row wpb_row vc_row-fluid vc_custom_1627545659074 vc_row-has-fill">
    <div class="wpb_column vc_column_container vc_col-sm-12 vc_col-has-fill">
      <div class="vc_column-inner vc_custom_1627545669829">
        <div class="wpb_wrapper">
          <div class="vc_row wpb_row vc_inner vc_row-fluid vc_custom_1627545677853 vc_row-has-fill">
            <div class="wpb_column vc_column_container vc_col-sm-12 vc_col-has-fill">
              <div class="vc_column-inner vc_custom_1627545830089">
                <div class="wpb_wrapper">
                  <h3 class="vbogs-title-28px vbogs-clearfix vbogs-title-center">Hotel Facilities</h3>
                  <section class="vbogs-icon-text-wrapper-1 vbogs-clearfix">
                    <div *ngFor="let facilitie of facilities" class="vbogs-icon-text-block vbogs-clearfix">
                      <div class="vbogs-icon"><i [ngClass]="facilitie.image"></i></div>
                      <div class="vbogs-text">
                        <h4>{{facilitie.title}}</h4>
                        <p [innerHTML]="facilitie.description"></p>
                      </div>
                    </div>
                  </section>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>