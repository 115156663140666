import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SharedModule } from './shared/shared.module';
import { HomeComponent } from './home/home.component';
import { AboutusComponent } from './aboutus/aboutus.component';
import { ContactusComponent } from './contactus/contactus.component';
import { HotelsComponent } from './hotels/hotels.component';
import { HotelDetailsComponent } from './hotel-details/hotel-details.component';
import { BookingComponent } from './booking/booking.component';
import { GuestLoginComponent } from './guest-login/guest-login.component';
import { BookingHistoryComponent } from './booking-history/booking-history.component';
import { PaymentSuccessComponent } from './payment-success/payment-success.component';
import { GroundsComponent } from './grounds/grounds.component';
import { GroundDetailsComponent } from './ground-details/ground-details.component';
import { GroundBookingComponent } from './ground-booking/ground-booking.component';
import { AppService } from './shared/app-service.service';
import { HttpClientModule } from '@angular/common/http';

import { ToastrModule } from 'ngx-toastr';
import { NgxUiLoaderModule, NgxUiLoaderConfig, NgxUiLoaderHttpModule } from 'ngx-ui-loader';
import { EventsComponent } from './events/events.component';
import { EventDetailsComponent } from './event-details/event-details.component';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { BlogsComponent } from './blogs/blogs.component';

import { ShareButtonsModule } from 'ngx-sharebuttons/buttons';
import { ShareIconsModule } from 'ngx-sharebuttons/icons';
import { BlogDetailsComponent } from './blog-details/blog-details.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MaterialModule } from './material.module';
import { SeriesDetailsComponent } from './series-details/series-details.component';
import { SeriesComponent } from './series/series.component';
import { MatchDetailsComponent } from './match-details/match-details.component';

const ngxUiLoaderConfig: NgxUiLoaderConfig =
{
  "bgsColor": "red",
  "bgsOpacity": 0.5,
  "bgsPosition": "bottom-right",
  "bgsSize": 60,
  "bgsType": "rectangle-bounce-pulse-out-rapid",
  "blur": 5,
  "delay": 0,
  "fastFadeOut": true,
  "fgsColor": "#7F9A27",
  "fgsPosition": "center-center",
  "fgsSize": 60,
  "fgsType": "rectangle-bounce-pulse-out",
  "gap": 24,
  "logoPosition": "center-center",
  "logoSize": 120,
  "logoUrl": "",
  "masterLoaderId": "master",
  "overlayBorderRadius": "0",
  "overlayColor": "rgba(40, 40, 40, 0.8)",
  "pbColor": "red",
  "pbDirection": "ltr",
  "pbThickness": 3,
  "hasProgressBar": true,
  "text": "Loading..",
  "textColor": "#FFFFFF",
  "textPosition": "center-center",
  "maxTime": -1,
  "minTime": 300
}

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    AboutusComponent,
    ContactusComponent,
    HotelsComponent,
    HotelDetailsComponent,
    BookingComponent,
    GuestLoginComponent,
    BookingHistoryComponent,
    PaymentSuccessComponent,
    GroundsComponent,
    GroundDetailsComponent,
    GroundBookingComponent,
    EventsComponent,
    EventDetailsComponent,
    BlogsComponent,
    BlogDetailsComponent,
    SeriesComponent,
    SeriesDetailsComponent,
    MatchDetailsComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    SharedModule,
    HttpClientModule,
    CommonModule,
    FormsModule,
    ShareButtonsModule,
    ShareIconsModule,
    NgxUiLoaderModule.forRoot(ngxUiLoaderConfig),
    NgxUiLoaderHttpModule.forRoot({ showForeground: true }),
    ToastrModule.forRoot({
      timeOut: 10000,
      positionClass: 'toast-top-right',
      preventDuplicates: true,
    }),
    BrowserAnimationsModule,
    MaterialModule
  ],
  providers: [AppService],
  bootstrap: [AppComponent]
})
export class AppModule { }
