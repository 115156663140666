import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { AppService } from '../shared/app-service.service';
declare var $:any;

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  hotels:any = [];
  pageData:any = {};
  bannersData:any = [];
  imageBasePath = environment.imageBasePath;
  constructor(private _AppService:AppService) { }

  ngOnInit(): void {
    this.getHotels();
    
    this._AppService.getPageDetails("Home").subscribe((res:any)=>{
      this.pageData = res;
    });
    this._AppService.getbanners().subscribe((res:any)=>{
      this.bannersData = res;
      setTimeout(()=>{
          $('.flexslider').flexslider({
            animation: "slide",
                animationLoop: true,
                pausePlay: true,
                mousewheel: false,
                rtl: true,
          });
      },100)
    });
  }

  getHotels() {
    this._AppService.gethotelsList().subscribe((res:any)=>{
      this.hotels = res;
      setTimeout(()=>{
        $('.vbogs-owl-carousel-3').owlCarousel({
          loop: false,
          margin: 30,
          nav: false,
          pagination: true,
          navText: "",
          autoHeight: false,
          responsive: {
            0: {
              items: 1
            },
            710: {
              items: 2
            },
          }
        });
      },100)
    })
  }

}
