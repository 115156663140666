import { Component, OnInit } from '@angular/core';
import { AppService } from '../app-service.service';

@Component({
  selector: 'app-hotel-facilities',
  templateUrl: './hotel-facilities.component.html',
  styleUrls: ['./hotel-facilities.component.scss']
})
export class HotelFacilitiesComponent implements OnInit {
  facilities:any = [];
  constructor(private appService:AppService) { }

  ngOnInit(): void {
    this.appService.getfacilitiesList().subscribe((res:any)=>{
      this.facilities = res;
    })
  }

}
