import { Component, OnInit } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { environment } from 'src/environments/environment';
import { AppService } from '../shared/app-service.service';

@Component({
  selector: 'app-series-details',
  templateUrl: './series-details.component.html',
  styleUrls: ['./series-details.component.scss']
})
export class SeriesDetailsComponent implements OnInit {

  series:any = [];
  matchs:any = [];
  seriesId ='';
  imageBasePath = environment.imageBasePath;
  constructor(private _AppService:AppService,private route: ActivatedRoute,private toastrService:ToastrService,private meta: Meta) { 
    this.route.params.subscribe(params => {
      this.seriesId = params['serieid'];
    })
    this._AppService.getContest().subscribe((res:any)=>{
      this.matchs = res.filter((item:any)=>{return item.seriesId == this.seriesId});
    })
  }

  ngOnInit(): void {
    this._AppService.getSeriesDetails(this.seriesId).subscribe((res:any)=>{
      this.series = res;
      this.meta.addTags([ 
          { name: 'description', content: this.series.description }, 
          { name: 'title', content: this.series.name },
          { name: 'og:url', content:`https://vbogs.com/series-details/${this.seriesId}` },
          { name: 'og:type', content: 'article' }
      ]);
    });

  }

}
