<div class="vbogs-content-wrapper vbogs-clearfix">
    <!-- BEGIN .vbogs-main-content -->
    <div class="vbogs-main-content vbogs-main-content-full-width vbogs-blog-single-page">
        <!-- BEGIN .vbogs-blog-single-wrapper -->
        <div class="vbogs-blog-single-wrapper">
            <!-- BEGIN .vbogs-blog-block -->
            <div *ngIf="series.name"
                class="vbogs-blog-block post-2113 post type-post status-publish format-standard has-post-thumbnail hentry category-city-breaks tag-accommodation">
               
                <h3 class="shb-blog-single-title">{{series.name}}</h3>
                <!-- BEGIN .vbogs-blog-meta -->
                
                <div class="vbogs-blog-single-image">
                    <img [src]="imageBasePath+series.image"
                        [alt]="series.eventTitle">
                        <div>
                            <share-buttons theme="modern-dark"
                                [include]="[ 'facebook', 'twitter', 'linkedin', 'whatsapp']"
                                [showIcon]="true"
                                [showText]="true"
                                url="https://vbogs.com/series-details/{{seriesId}}"
                                description="{{series.name}}"
                                image="{{imageBasePath+series.image}}"
                                class="pt-5">
                            </share-buttons>
                        </div>
                </div>
                
                <!-- BEGIN .vbogs-blog-description -->
                <div class="vbogs-blog-description vbogs-clearfix">

                    <div class="vc_row wpb_row vc_row-fluid">
                        <div class="wpb_column vc_column_container vc_col-sm-12">
                            <div class="vc_column-inner">
                                <div class="wpb_wrapper">
                                    <div class="wpb_text_column wpb_content_element ">
                                        <div class="wpb_wrapper" [innerHTML]="series.description"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- END .vbogs-blog-description -->
                </div>
                <h3 class="shb-blog-single-title">Matchs In Series</h3>
                <div class="vc_row wpb_row vc_row-fluid">
                    <div class="wpb_column vc_column_container vc_col-sm-12">
                        <div class="vc_column-inner">
                            <div class="wpb_wrapper">
                                <!-- BEGIN .vbogs-blog-wrapper-2 -->
                                <div class="vbogs-blog-wrapper-2 vbogs-blog-wrapper-2-3-col vbogs-clearfix">
                                    <!-- BEGIN .vbogs-blog-block -->
                                    <div *ngFor="let match of matchs" id="post-2113"
                                        class="vbogs-blog-block vbogs-clearfix post-2113 post type-post status-publish format-standard has-post-thumbnail hentry category-city-breaks tag-accommodation">
                                        <div class="vbogs-blog-block-image">
                                            <a rel="bookmark" href="/match-details/{{match.id}}" title="{{match.name}}">
                                                <img class="shb-accommodation-listing-image" [src]="imageBasePath+match.image" alt="{{match.name}}"> </a>
                                        </div>
                                        <!-- BEGIN .vbogs-blog-title-meta-wrapper -->
                                        <div class="vbogs-blog-title-meta-wrapper">
                                            <h3><a rel="bookmark" href="/match-details/{{match.id}}" title="{{match.name}}">{{match.name}}</a></h3>
                                        </div>
                                    </div>
                                </div>
                                <div class="vbogs-clearboth"></div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- END .vbogs-blog-block -->
            </div>
        </div>
        <!-- END .vbogs-main-content -->
    </div>

    <!-- END .vbogs-content-wrapper -->
</div>