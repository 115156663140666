import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AppService {

  hotels:any = [];
  constructor(private http: HttpClient) { }

  api: string = environment.baseUrl;

  gethotelsList() {
    return this.http.get(this.api + 'hotels');
  }

  gethotelById(id:any) {
    return this.http.get(this.api + 'hotels/'+id)
  }

  getfacilitiesList() {
    return this.http.get(this.api + 'facilities');
  }

  getspecialoffersList() {
    return this.http.get(this.api + 'specialoffers');
  }

  getPageDetails(pageName:any) {
    return this.http.get(this.api + 'page/'+pageName);
  }

  getbanners() {
    return this.http.get(this.api + 'banners');
  }

  getevents() {
    return this.http.get(this.api + 'events');
  }

  geteventDetails(id:any) {
    return this.http.get(this.api + 'events/'+id);
  }

  postEventMember(data:any) {
    return this.http.post(this.api + 'eventMembers',data);
  }

  postBooking(data:any) {
    return this.http.post(this.api + 'bookings',data);
  }

  getgroundsList() {
    return this.http.get(this.api + 'grounds');
  }

  getgroundById(id:any) {
    return this.http.get(this.api + 'grounds/'+id)
  }

  slotbookings(data:any,groundId:any) {
    return this.http.get(this.api + `slotbookings/${data}/${groundId}`)
  }

  getblogs() {
    return this.http.get(this.api + 'blogs');
  }

  getblogDetails(id:any) {
    return this.http.get(this.api + 'blogs/'+id);
  }

  getSlots() {
    return this.http.get(this.api + 'slots');
  }

  getSeries() {
    return this.http.get(this.api + 'series');
  }

  getSeriesDetails(id:any) {
    return this.http.get(this.api + 'series/'+id);
  }

  getContest() {
    return this.http.get(this.api + 'contests');
  }

  getContestDetails(id:any) {
    return this.http.get(this.api + 'contests/'+id);
  }
}
