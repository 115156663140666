<div class="vbogs-page-header" style="background-image:url('../../assets/images/08-6.jpg');">
    <h1>Blogs</h1>
    <!-- END .vbogs-page-header -->
</div>
<div class="vbogs-content-wrapper vbogs-clearfix vbogs-content-wrapper-full-width">
    <!-- BEGIN .vbogs-main-content -->
    <div class="vbogs-main-content vbogs-main-content-full-width">
        <div class="vc_row wpb_row vc_row-fluid">
            <div class="wpb_column vc_column_container vc_col-sm-12">
                <div class="vc_column-inner">
                    <div class="wpb_wrapper">
                        <!-- BEGIN .vbogs-blog-wrapper-2 -->
                        <div class="vbogs-blog-wrapper-2 vbogs-blog-wrapper-2-3-col vbogs-clearfix">
                            <!-- BEGIN .vbogs-blog-block -->
                            <div *ngFor="let blog of blogs" id="post-2113"
                                class="vbogs-blog-block vbogs-clearfix post-2113 post type-post status-publish format-standard has-post-thumbnail hentry category-city-breaks tag-accommodation">
                                <div class="vbogs-blog-block-image">
                                    <a rel="bookmark" href="/blog-details/{{blog.id}}" title="{{blog.name}}">
                                        <img class="shb-accommodation-listing-image" [src]="imageBasePath+blog.image" alt="{{blog.name}}"> </a>
                                </div>
                                <!-- BEGIN .vbogs-blog-title-meta-wrapper -->
                                <div class="vbogs-blog-title-meta-wrapper">
                                    <h3><a rel="bookmark" href="/blog-details/{{blog.id}}" title="{{blog.name}}">{{blog.name}}</a></h3>
                                    <div class="vbogs-blog-meta clearfix">
                                        <span class="vbogs-blog-meta-author"><i class="fas fa-calendar-day"></i><a
                                            title="{{blog.name}}">{{blog.blogDate |date:'MMMM d, y'}}</a></span>
                                        <span class="vbogs-blog-meta-author"><i class="fas fa-pencil-alt"></i>By <a
                                               title="Posts by {{blog.blogBy}}" rel="author">{{blog.blogBy}}</a></span>
                                        <!-- END .vbogs-blog-meta -->
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="vbogs-clearboth"></div>
                    </div>
                </div>
            </div>
        </div>
        <!-- END .vbogs-main-content -->
    </div>
    <!-- END .vbogs-content-wrapper -->
</div>