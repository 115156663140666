<div class="vbogs-content-wrapper vbogs-clearfix vbogs-content-wrapper-full-width">

    <!-- BEGIN .vbogs-main-content -->
    <div class="vbogs-main-content vbogs-main-content-full-width">
        <div class="vc_row wpb_row vc_row-fluid">
            <div class="wpb_column vc_column_container vc_col-sm-12">
                <div class="vc_column-inner">
                    <div class="wpb_wrapper">
                        <!-- BEGIN .shb-booking-page-wrapper -->
                        <div class="shb-booking-page-wrapper shb-clearfix">
                            <!-- BEGIN .shb-booking-page-main -->
                            <div class="shb-booking-page-main" *ngIf="!isBookingDone">
                                <!-- BEGIN .shb-booking-accommodation-result-wrapper -->
                                <form class="shb-booking-accommodation-result-wrapper" action="/bookings/"
                                    method="post">
                                    <!-- BEGIN .shb-booking-accommodation-item -->
                                    <div
                                        class="shb-booking-accommodation-item  shb-clearfix shb-booking-accommodation-available">
                                        <!-- BEGIN .shb-clearfix -->
                                        <div class="shb-clearfix">
                                            <!-- BEGIN .shb-booking-accommodation-image -->
                                            <div class="shb-booking-accommodation-image">
                                                <a *ngIf="ground.name" href="" class="shb-lightbox-open">
                                                    <img [src]="imageBasePath+ground.image" alt="{{ground.name}}">
                                                </a>
                                                <!-- END .shb-booking-accommodation-image -->
                                            </div>
                                            <!-- BEGIN .shb-booking-accommodation-info -->
                                            <div class="shb-booking-accommodation-info-wrapper">
                                                <!-- BEGIN .shb-booking-accommodation-info -->
                                                <div class="shb-booking-accommodation-info">
                                                    <h3><a>{{ground.name}}</a></h3>
                                                    <ul class="hotel-detail">
                                                        <li><i class="fas fa-ruler-combined"></i><span>Size:</span>
                                                            {{ground.dimentions}}sqm</li>
                                                    </ul>
                                                    <!-- END .shb-booking-accommodation-info -->
                                                </div>
                                            </div>
                                            <!-- END .shb-clearfix -->
                                        </div>
                                        <!-- END .shb-booking-accommodation-item -->
                                    </div>
                                    <!-- END .shb-booking-accommodation-result-wrapper -->
                                </form>

                                <!-- END .shb-booking-page-main -->
                                <form name="checkout" *ngIf="!paymentProceess" method="post"
                                    class="checkout woocommerce-checkout" (submit)="requestBooking()">
                                    <div class="col2-set" id="customer_details">
                                        <div class="col-1">
                                            <div class="woocommerce-billing-fields">
                                                <div class="woocommerce-billing-fields__field-wrapper">
                                                    <p class="form-row form-row-first validate-required"
                                                        id="billing_first_name_field" data-priority="10">
                                                        <label for="billing_first_name" class="">First name&nbsp;<b
                                                                class="required" title="required">*</b></label>
                                                        <span class="woocommerce-input-wrapper">
                                                            <input type="text" class="input-text "
                                                                name="billing_first_name" id="billing_first_name"
                                                                placeholder="" value="" required
                                                                autocomplete="given-name"
                                                                [(ngModel)]="pageData.firstName">
                                                        </span>
                                                    </p>
                                                    <p class="form-row form-row-last validate-required"
                                                        id="billing_last_name_field" data-priority="20">
                                                        <label for="billing_last_name" class="">Last name&nbsp;<b
                                                                class="required" title="required">*</b></label>
                                                        <span class="woocommerce-input-wrapper">
                                                            <input type="text" class="input-text "
                                                                name="billing_last_name" id="billing_last_name"
                                                                placeholder="" value="" required
                                                                autocomplete="family-name"
                                                                [(ngModel)]="pageData.lastName">
                                                        </span>
                                                    </p>
                                                    <div></div>
                                                    <p class="form-row form-row-wide validate-required validate-phone"
                                                        id="billing_phone_field" data-priority="100"
                                                        style="clear: left;">
                                                        <label for="billing_phone" class="">Phone&nbsp;<b
                                                                class="required" title="required">*</b></label>
                                                        <span class="woocommerce-input-wrapper">
                                                            <input type="tel" class="input-text " name="billing_phone"
                                                                id="billing_phone" placeholder="" value=""
                                                                autocomplete="tel" required
                                                                [(ngModel)]="pageData.phone">
                                                        </span>
                                                    </p>
                                                    <p class="form-row form-row-wide validate-required validate-email"
                                                        id="billing_email_field" data-priority="110">
                                                        <label for="billing_email" class="">Email address&nbsp;<b
                                                                class="required" title="required">*</b></label>
                                                        <span class="woocommerce-input-wrapper">
                                                            <input type="email" class="input-text " name="billing_email"
                                                                id="billing_email" placeholder="" value=""
                                                                autocomplete="email" required
                                                                [(ngModel)]="pageData.email">
                                                        </span>
                                                    </p>
                                                    <div></div>
                                                    <p class="form-row form-row-wide validate-required">
                                                        <label for="bookingdate" class="">Booking Date&nbsp;<b
                                                                class="required" title="required">*</b></label>
                                                        <mat-form-field appearance="outline">
                                                            <input id="bookingdate" [min]="currentDate"
                                                                (dateChange)="bookingDateChange($event)"
                                                                [matDatepickerFilter]="myFilter" matInput
                                                                [matDatepicker]="picker">
                                                            <mat-datepicker-toggle matIconSuffix
                                                                [for]="picker"></mat-datepicker-toggle>
                                                            <mat-datepicker touchUi #picker></mat-datepicker>
                                                        </mat-form-field>

                                                    </p>
                                                    <p class="form-row form-row-wide" id="timeslot_field"
                                                        data-priority="110">
                                                        <label for="timeslot" class="">Time Slot&nbsp;<b
                                                                class="required" title="required">*</b></label>
                                                        <span class="woocommerce-input-wrapper">
                                                            <select id="timeslot" class="input-text" required
                                                                name="slotId" [(ngModel)]="pageData.slotId">
                                                                <option value="">Please Select</option>
                                                                <option *ngFor="let slot of slots"
                                                                    [disabled]="slot.availability <= 0"
                                                                    [value]="slot.id">{{slot.slotName}}</option>
                                                            </select>
                                                        </span>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>

                                        <div id="order_review" class="woocommerce-checkout-review-order">
                                            <div id="payment" class="woocommerce-checkout-payment">
                                                <div class="form-row place-order">
                                                    <div class="woocommerce-terms-and-conditions-wrapper">
                                                        <div class="woocommerce-privacy-policy-text">
                                                            <p>Your personal data will be used to process your order,
                                                                support your experience throughout this website, and for
                                                                other purposes described in our <a
                                                                    class="woocommerce-privacy-policy-link"
                                                                    target="_blank">privacy policy</a>.</p>
                                                        </div>
                                                    </div>
                                                    <div id="place_order">
                                                        <button type="submit" class="button alt"
                                                            name="woocommerce_checkout_place_order"
                                                            class="shb-booking-continue" value="Place order"
                                                            data-value="Place order">Pay Now</button>
                                                    </div>

                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </form>
                                <div id="card-container"></div>
                                <div class="payment-btn">
                                    <button id="card-button" *ngIf="paymentProceess" type="button">Pay
                                        ${{ground.price}}</button>
                                </div>
                                <div id="payment-status-container" *ngIf="paymentProceess"></div>
                            </div>

                            <div class="shb-booking-page-main" *ngIf="isBookingDone">
                                <!-- BEGIN .shb-booking-complete-wrapper -->
                                <div class="shb-booking-complete-wrapper">

                                    <i class="fas fa-check"></i>
                                    <h3>Booking Complete! (#0{{bookingId}})</h3>

                                    <!-- END .shb-booking-complete-wrapper -->
                                </div>
                                <!-- BEGIN .shb-booking-notification-wrapper -->
                                <div class="shb-booking-notification-wrapper">
                                    <p style="padding: 10px 0 20px 0;
                                    font-size: 18px;
                                    font-weight: 500;"><i class="fas fa-envelope"></i>A confirmation email has been
                                        sent to
                                        {{pageData.email}}</p>
                                    <!-- END .shb-booking-notification-wrapper -->
                                    <h3 class="vbogs-title-20px vbogs-clearfix vbogs-title-left">Thank You
                                    </h3>
                                    <p class="shb-booking-confirmation-message">Thank you for booking, we look forward
                                        to
                                        welcoming you to our ground soon, and in the meantime if you have any questions
                                        please do not hesitate to get in touch with us via phone or email and we’ll be
                                        happy
                                        to hear from you.</p>
                                </div>
                                <!-- END .shb-booking-page-main -->
                            </div>
                            <!-- BEGIN .shb-booking-page-sidebar -->
                            <div class="shb-booking-page-sidebar">
                                <!-- BEGIN .shb-booking-your-stay-wrapper -->
                                <div class="shb-booking-your-stay-wrapper">
                                    <!-- BEGIN .shb-booking-your-stay-items-wrapper -->
                                    <div class="shb-booking-your-stay-items-wrapper">
                                        <!-- BEGIN .shb-booking-your-stay-item-wrapper -->
                                        <div class="shb-booking-your-stay-item-wrapper" *ngIf="ground.name">
                                            <h3>Ground 1 of 1</h3>
                                            <!-- BEGIN .shb-booking-your-stay-item -->
                                            <div class="shb-booking-your-stay-item shb-clearfix">
                                                <a class="shb-booking-stay-image">
                                                    <img [src]="imageBasePath+ground.image" alt="Bookings"> </a>

                                                <div class="shb-booking-your-stay-item-info">
                                                    <h4 class="shb-clearfix">
                                                        <a>{{ground.name}}</a><span>${{ground.price}}</span>
                                                    </h4>
                                                    <p class="shb-booking-your-stay-item-info-detail">Full Board</p>

                                                </div>
                                                <!-- END .shb-booking-your-stay-item -->
                                            </div>
                                            <!-- BEGIN .shb-booking-your-stay-controls -->

                                        </div>
                                        <!-- END .shb-booking-your-stay-items-wrapper -->
                                    </div>
                                    <!-- BEGIN .shb-booking-total -->
                                    <div class="shb-booking-total shb-clearfix">
                                        <h4>Total</h4>
                                        <h4>
                                            ${{ground.price}}
                                        </h4>
                                        <!-- END .shb-booking-total -->
                                    </div>
                                    <!-- END .shb-booking-your-stay-wrapper -->
                                </div>
                                <div class="shb-booking-contact-wrapper">

                                    <h3>Need Assistance?</h3>
                                    <p>Our dedicated reservations team is ready to help you around the clock.</p>
                                    <ul>
                                        <li><i class="fas fa-map-marker-alt"></i><a [target]="'_blank'"
                                                href="https://www.google.com/maps/place/33%C2%B017'10.0%22N+95%C2%B052'20.3%22W/@33.2861081,-95.8728432,19z/data=!3m1!4b1!4m14!1m7!3m6!1s0x864bc83b3473822f:0x1b8f5491bd7736ba!2s7488+TX-24,+Commerce,+TX+75428,+USA!3b1!8m2!3d33.288513!4d-95.8706909!3m5!1s0x0:0x9cdb84b8597769e!7e2!8m2!3d33.2861074!4d-95.8722964"
                                                target="blank">7488 TX-24, Commerce, TX, United States, Texas</a></li>
                                        <li><i class="fas fa-phone"></i><a href="tel:+1 952-838-5687">+1
                                                952-838-5687</a>
                                        </li>
                                        <li><i class="fas fa-envelope"></i><a
                                                href="mailto:vj@vbogs.com">vj@vbogs.com</a></li>
                                    </ul>

                                </div>
                                <!-- END .shb-booking-page-sidebar -->
                            </div>
                            <!-- END .shb-booking-page-wrapper -->
                        </div>

                    </div>
                </div>
            </div>
        </div>
        <!-- END .vbogs-main-content -->
    </div>
    <!-- END .vbogs-content-wrapper -->
</div>