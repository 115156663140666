<div class="vbogs-content-wrapper vbogs-clearfix">
    <!-- BEGIN .vbogs-main-content -->
    <div class="vbogs-main-content vbogs-main-content-full-width vbogs-blog-single-page">
        <!-- BEGIN .vbogs-blog-single-wrapper -->
        <div class="vbogs-blog-single-wrapper">
            <!-- BEGIN .vbogs-blog-block -->
            <div *ngIf="event.eventTitle"
                class="vbogs-blog-block post-2113 post type-post status-publish format-standard has-post-thumbnail hentry category-city-breaks tag-accommodation">
               
                <h3 class="shb-blog-single-title">{{event.eventTitle}}</h3>
                <!-- BEGIN .vbogs-blog-meta -->
                <div class="vbogs-blog-meta clearfix">
                    <span class="vbogs-blog-meta-author"><i class="fas fa-calendar"></i><a
                            rel="bookmark" title="Celebrating 20th Anniversary Of Vbogs Resorts">{{event.eventDate |date:'MMMM d, Y'}}</a></span>
                    <span class="vbogs-blog-meta-author"><i class="fas fa-pencil-alt"></i>By <a
                            title="Posts by admin2021" rel="author">{{event.eventBy}}</a></span>
                    <span class="vbogs-blog-meta-category"><i class="fas fa-tag"></i><a
                            rel="category tag">{{event.eventType}}</a></span>
                    <!-- END .vbogs-blog-meta -->
                </div>
                <div class="vbogs-blog-single-image">
                    <img [src]="imageBasePath+event.image"
                        [alt]="event.eventTitle">
                        <div>
                            <share-buttons theme="modern-dark"
                                [include]="[ 'facebook', 'twitter', 'linkedin', 'whatsapp']"
                                [showIcon]="true"
                                [showText]="true"
                                url="https://vbogs.com/events/{{eventId}}"
                                description="{{event.eventTitle}}"
                                image="https://vbogs.com/assets/Srinivasa_Kalyanam_300x300.jpg"
                                class="pt-5">
                            </share-buttons>
                        </div>
                </div>
                
                <!-- BEGIN .vbogs-blog-description -->
                <div class="vbogs-blog-description vbogs-clearfix">

                    <div class="vc_row wpb_row vc_row-fluid">
                        <div class="wpb_column vc_column_container vc_col-sm-12">
                            <div class="vc_column-inner">
                                <div class="wpb_wrapper">
                                    <div class="wpb_text_column wpb_content_element ">
                                        <div class="wpb_wrapper" [innerHTML]="event.description"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- END .vbogs-blog-description -->
                </div>

                <!-- END .vbogs-blog-block -->
            </div>

            <!-- BEGIN .vbogs-comments-wrapper -->
            <div class="vbogs-comments-wrapper" style="margin-bottom: 20px;">
                <div id="respond" class="comment-respond">
                    <h3 id="reply-title" class="comment-reply-title">RSVP
                        <p class="comment-reply-title">Please let us know if you will be able to make it.</p>
                    </h3>
                    
                    <form method="post" (submit)="postSubmit()"
                        id="commentform" class="comment-form">
                        <label>Name <span class="vbogs-required">*</span></label>
                        <input id="author" required [(ngModel)]="eventData.name" name="author" type="text" value="" placeholder="Name">
                        <label>Email <span class="vbogs-required">*</span></label>
                        <input id="email" required [(ngModel)]="eventData.email" name="email" type="text" value="" placeholder="Email">
                        <label>Phone <span class="vbogs-required">*</span></label>
                        <input id="phone" required [(ngModel)]="eventData.phone" name="phone" type="text" value=""  placeholder="Phone">
                        <label>No Of Adults <span class="vbogs-required">*</span></label>
                        <input id="author" required [(ngModel)]="eventData.noOfAdults" name="NoOfAdults" type="text" value="" placeholder="No Of Adults">
                        <label>No Of Childs <span class="vbogs-required">*</span></label>
                        <input id="author" [(ngModel)]="eventData.noOfChilds" name="NoOfChilds" type="text" value="" placeholder="No Of Childs">

                        <p class="form-submit"><input name="submit" type="submit" id="submit-button" class="submit"
                                value="Submit RSVP"> <input type="hidden" name="comment_post_ID" value="2113"
                                id="comment_post_ID">
                            <input type="hidden" name="eventId" id="eventId" [(ngModel)]="eventData.noOfAdults" [value]="eventId">
                        </p>
                    </form>
                </div><!-- #respond -->
                <!-- END .vbogs-comments-wrapper -->
            </div>
            <!-- END .vbogs-blog-single-wrapper -->
        </div>
        <!-- END .vbogs-main-content -->
    </div>

    <!-- END .vbogs-content-wrapper -->
</div>