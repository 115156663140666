import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { AppService } from '../shared/app-service.service';
declare var $:any;
@Component({
  selector: 'app-ground-details',
  templateUrl: './ground-details.component.html',
  styleUrls: ['./ground-details.component.scss']
})
export class GroundDetailsComponent implements OnInit {

  ground:any = [];
  groundId:any = '';
  imageBasePath = environment.imageBasePath;
  isOpen = '';
  constructor(private _AppService:AppService,private route: ActivatedRoute,private router:Router) { 
    this.route.params.subscribe(params => {
      this.groundId = params['groundid'];
    })
  }

  ngOnInit(): void {
    this.getHotel();
  }

  getHotel() {
    this._AppService.getgroundById(this.groundId).subscribe((res:any)=>{
      this.ground = res;
      setTimeout(()=>{
        $('.flexslider').flexslider({
          animation: "slide",
              animationLoop: true,
              pausePlay: true,
              mousewheel: false,
              rtl: true,
        });
    },100)
    })
  }

  getHotelAmenities() {
    
  }

  requestBooking() {
    this.router.navigateByUrl('ground-booking/'+this.groundId);
  }

  openqa(type:any) {
    if(this.isOpen == type) {
      this.isOpen = '';
    } else {
      this.isOpen = type;
    }
  }
}
