import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { environment } from 'src/environments/environment';
import { AppService } from '../shared/app-service.service';
import { Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-event-details',
  templateUrl: './event-details.component.html',
  styleUrls: ['./event-details.component.scss']
})
export class EventDetailsComponent implements OnInit {

  event:any = [];
  eventId ='';
  imageBasePath = environment.imageBasePath;
  eventData:any ={
    id:'',
    eventId:'',
    name:'',
    email:'',
    phone:'',
    noOfAdults:'',
    noOfChilds:''
  };
  constructor(private _AppService:AppService,private route: ActivatedRoute,private toastrService:ToastrService, private meta: Meta) { 
    this.route.params.subscribe(params => {
      this.eventId = params['eventid'];
      this.eventData.eventId = this.eventId;
    })
  }

  ngOnInit(): void {
    this._AppService.geteventDetails(this.eventId).subscribe((res:any)=>{
      this.event = res;
      this.meta.addTags([ 
        { name: 'title', content: this.event.name },
        { name: 'og:url', content:`https://vbogs.com/events/${this.eventId}` },
        { name: 'og:type', content: 'article' }
    ]);
    });

  }

  postSubmit() {
    this._AppService.postEventMember(this.eventData).subscribe(()=>{
      this.toastrService.success("Information Added Succefully!!","Success");
      this.eventData ={
        id:'',
        eventId:this.eventId,
        name:'',
        email:'',
        phone:'',
        noOfAdults:'',
        noOfChilds:''
      };
    })
    return false;
  }

}
