import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { AppService } from '../shared/app-service.service';
declare var $:any;
@Component({
  selector: 'app-hotel-details',
  templateUrl: './hotel-details.component.html',
  styleUrls: ['./hotel-details.component.scss']
})
export class HotelDetailsComponent implements OnInit {

  hotel:any = [];
  hotelId:any = '';
  imageBasePath = environment.imageBasePath;
  isOpen = '';
  constructor(private _AppService:AppService,private route: ActivatedRoute,private router:Router) { 
    this.route.params.subscribe(params => {
      this.hotelId = params['hotelid'];
    })
  }

  ngOnInit(): void {
    this.getHotel();
  }

  getHotel() {
    this._AppService.gethotelById(this.hotelId).subscribe((res:any)=>{
      this.hotel = res;
      setTimeout(()=>{
        $('.flexslider').flexslider({
          animation: "slide",
              animationLoop: true,
              pausePlay: true,
              mousewheel: false,
              rtl: true,
        });
    },100)
    })
  }

  getHotelAmenities() {
    
  }

  requestBooking() {
    this.router.navigateByUrl('booking/'+this.hotelId);
  }

  tourBooking() {
    this.router.navigateByUrl('tourBooking/'+this.hotelId);
  }

  openqa(type:any) {
    if(this.isOpen == type) {
      this.isOpen = '';
    } else {
      this.isOpen = type;
    }
  }

}
