<div class="vbogs-content-wrapper vbogs-clearfix">
    <!-- BEGIN .vbogs-main-content -->
    <div class="vbogs-main-content vbogs-main-content-full-width vbogs-blog-single-page">
        <!-- BEGIN .vbogs-blog-single-wrapper -->
        <div class="vbogs-blog-single-wrapper">
            <!-- BEGIN .vbogs-blog-block -->
            <div *ngIf="match.name"
                class="vbogs-blog-block post-2113 post type-post status-publish format-standard has-post-thumbnail hentry category-city-breaks tag-accommodation">
               
                <h3 class="shb-blog-single-title">{{match.name}}</h3>

                <div class="wpb_text_column wpb_content_element" *ngIf="match.seriesId == 1">
                    <mat-tab-group>
                        <mat-tab [label]="'Summary'"><div class="wpb_wrapper" [innerHTML]="match.raceSummary"></div></mat-tab>
                        <mat-tab [label]="match.teamA"><div class="wpb_wrapper" [innerHTML]="match.teamASummary"></div></mat-tab>
                        <mat-tab [label]="match.teamB"><div class="wpb_wrapper" [innerHTML]="match.teamBSummary"></div></mat-tab>
                    </mat-tab-group>
                </div>
                <div class="wpb_text_column wpb_content_element"  *ngIf="match.seriesId == 2">
                    <mat-tab-group>
                        <mat-tab [label]="'Summary'"><div class="wpb_wrapper" [innerHTML]="match.raceSummary"></div></mat-tab>
                    </mat-tab-group>
                </div>
                
                <div class="vbogs-blog-single-image">
                    <div>
                        <share-buttons theme="modern-dark"
                            [include]="[ 'facebook', 'twitter', 'linkedin', 'whatsapp']"
                            [showIcon]="true"
                            [showText]="true"
                            url="https://vbogs.com/match-details/{{matchId}}"
                            description="{{match.name}}"
                            image="{{imageBasePath+match.thumbnail}}"
                            class="pt-5">
                        </share-buttons>
                    </div>
            </div>

                <!-- END .vbogs-blog-block -->
            </div>
        </div>
        <!-- END .vbogs-main-content -->
    </div>

    <!-- END .vbogs-content-wrapper -->
</div>