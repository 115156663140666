import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-guest-login',
  templateUrl: './guest-login.component.html',
  styleUrls: ['./guest-login.component.scss']
})
export class GuestLoginComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
