<div class="vbogs-page-header"
    style="background-image:url('../../assets/images/08-6.jpg');">
    <h1>Accommodation</h1>
    <!-- END .vbogs-page-header -->
</div>
<div class="vbogs-content-wrapper vbogs-clearfix vbogs-content-wrapper-full-width">
    <!-- BEGIN .vbogs-main-content -->
    <div class="vbogs-main-content vbogs-main-content-full-width">
        <div class="vc_row wpb_row vc_row-fluid">
            <div class="wpb_column vc_column_container vc_col-sm-12">
                <div class="vc_column-inner">
                    <div class="wpb_wrapper">
                        <!-- BEGIN .shb-accommodation-listing-filter-sorting -->
                        <!-- BEGIN .shb-accommodation-listing-style-1 -->
                        <div class="shb-accommodation-listing-style-1 shb-accommodation-listing-style-1-columns-2">
                            <app-room  *ngFor="let hotel of hotels" [hotel]="hotel"></app-room>
                        </div>
                        <div class="shb-clearboth" ></div>
                        <h3 style="margin-top: 20px;" class="vbogs-title-25px vbogs-clearfix vbogs-title-center">Booking
                            Policy</h3>
                        <div 
                            class="vc_toggle vc_toggle_default vc_toggle_color_default vc_toggle_size_md" [ngClass]="isOpen=='flight'?'vc_toggle_active':''">
                            <div  class="vc_toggle_title">
                                <h4 (click)="openqa('flight')">My flight arrives early in the morning, what time can I check
                                    in?</h4><i  class="vc_toggle_icon"></i>
                            </div>
                            <div class="vc_toggle_content" *ngIf="isOpen=='flight'">
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                                    Praesent et rhoncus neque. Maecenas enim nunc, dapibus in mattis eleifend, luctus et
                                    magna. Maecenas nunc est, posuere sed convallis tincidunt</p>
                            </div>
                        </div>
                        <div 
                            class="vc_toggle vc_toggle_default vc_toggle_color_default vc_toggle_size_md" [ngClass]="isOpen=='break'?'vc_toggle_active':''">
                            <div class="vc_toggle_title">
                                <h4 (click)="openqa('break')">Is breakfast included as standard with all rooms?</h4><i
                                     class="vc_toggle_icon"></i>
                            </div>
                            <div class="vc_toggle_content" *ngIf="isOpen=='break'">
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                                    Praesent et rhoncus neque. Maecenas enim nunc, dapibus in mattis eleifend, luctus et
                                    magna. Maecenas nunc est, posuere sed convallis tincidunt</p>
                            </div>
                        </div>
                        <div 
                            class="vc_toggle vc_toggle_default vc_toggle_color_default vc_toggle_size_md" [ngClass]="isOpen=='child'?'vc_toggle_active':''">
                            <div class="vc_toggle_title">
                                <h4 (click)="openqa('child')">Do you provide a child day care service?</h4><i
                                     class="vc_toggle_icon"></i>
                            </div>
                            <div class="vc_toggle_content" *ngIf="isOpen=='child'">
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                                    Praesent et rhoncus neque. Maecenas enim nunc, dapibus in mattis eleifend, luctus et
                                    magna. Maecenas nunc est, posuere sed convallis tincidunt</p>
                            </div>
                        </div>
                        <div class="shb-clearboth"></div>
                    </div>
                </div>
            </div>
        </div>
        <!-- END .vbogs-main-content -->
    </div>
    <!-- END .vbogs-content-wrapper -->
</div>