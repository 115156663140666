import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { environment } from 'src/environments/environment';
import { AppService } from '../shared/app-service.service';
declare var window: any;
@Component({
  selector: 'app-ground-booking',
  templateUrl: './ground-booking.component.html',
  styleUrls: ['./ground-booking.component.scss']
})
export class GroundBookingComponent implements OnInit {

  ground: any = [];
  groundId: any = '';
  isBookingDone = false;
  imageBasePath = environment.imageBasePath;
  bookingId = "";
  pageData = {
    bookingDate: "",
    firstName: "",
    email: "",
    lastName: "",
    phone: "",
    slotId: ""
  }
  pageType = '';
  slots: any = [];
  appId = 'sandbox-sq0idb-_UdWyp3kSDbaTljxt8qj3A';
  locationId = 'LDVGAR2ZQ2RFN';
  currentDate = new Date();
  aviableDate: any = [];
  bookingDate = '';
  paymentProceess = false;
  payments:any = {};
  constructor(private _AppService: AppService, private route: ActivatedRoute, public ngxUiLoader: NgxUiLoaderService) {
    this.route.params.subscribe(params => {
      this.groundId = params['groundid'];
    });

  }

  ngOnInit(): void {
    this.getGround();
    this._AppService.getSlots().subscribe((res: any) => {
      this.aviableDate = res.filter((item: any) => {
        return item.groundId == this.groundId
      });
    });
  }

  getGround() {
    this._AppService.getgroundById(this.groundId).subscribe((res: any) => {
      this.ground = res;
    })
  }

  requestBooking() {
    let data = {
      bookingTypeId: this.groundId,
      bookingDate: this.bookingDate,
      isActive: 1,
      status: "Confirmed",
      firstName: this.pageData.firstName,
      lastName: this.pageData.lastName,
      email: this.pageData.email,
      phone: this.pageData.phone,
      bookingType: 'ground',
      slotId: this.pageData.slotId
    }
    this._AppService.postBooking(data).subscribe((res: any) => {
      this.bookingId = res.bookingId;
      this.paymentProceess = true;
      this.ngxUiLoader.start();
      this.onBookNowClick();
    });

  }

  bookingDateChange(event: any) {
    //let date = new Date(event.value).toISOString().substring(0,10);
    let date = new Date(event.value);
    this.bookingDate = new Date(date.getTime() - (date.getTimezoneOffset() * 60000)).toISOString().split("T")[0];
    this._AppService.slotbookings(this.bookingDate, this.groundId).subscribe((res: any) => {
      this.slots = res;
    });
  }

  myFilter = (d: Date | null): boolean => {
    let aviableDate = 0;
    const day = (d || new Date()).toISOString().substring(0, 10);
    // Prevent Saturday and Sunday from being selected.
    aviableDate = this.aviableDate.filter((item: any) => {
      return new Date(item.slotDate).toISOString().substring(0, 10) == day;
    }).length;
    return aviableDate ? true : false;
  };

  async onBookNowClick() {
    if (!window.Square) {
      throw new Error('Square.js failed to load properly');
    }

    try {
      this.payments = window.Square.payments(this.appId, this.locationId);
    } catch {
      const statusContainer: any = document.getElementById(
        'payment-status-container'
      );
      statusContainer.className = 'missing-credentials';
      statusContainer.style.visibility = 'visible';
      return;
    }

    let card: any;
    try {
      card = await this.initializeCard(this.payments);
    } catch (e) {
      console.error('Initializing Card failed', e);
      return;
    }

    const cardButton: any = document.getElementById('card-button');
    cardButton.addEventListener('click', async (event: any) => {
      await this.handlePaymentMethodSubmission(event, card);
    });
  }

  async handlePaymentMethodSubmission(event: any, paymentMethod: any) {
    event.preventDefault();
    try {
      const token = await this.tokenize(paymentMethod);
      const verificationToken = await this.verifyBuyer(this.payments, token);
      const paymentResults = await this.createPayment(token,verificationToken);
      this.displayPaymentResults('SUCCESS');

      console.debug('Payment Success', paymentResults);
    } catch (e) {
      this.displayPaymentResults('FAILURE');
    }
  }

  async initializeCard(payments: any) {
    const card = await payments.card();
    await card.attach('#card-container');
    this.ngxUiLoader.stop();
    return card;
  }

  async createPayment(token: any,verificationToken:any) {
    this.ngxUiLoader.start();
    const body = JSON.stringify({
      verificationToken: verificationToken,
      token: token,
      amount: parseFloat(this.ground.price)*100
    });
    const paymentResponse = await fetch(environment.paymentUrl + 'createPayment', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body,
    });

    if (paymentResponse.ok) {
      this.ngxUiLoader.stop();
      this.isBookingDone = true;
      return paymentResponse.json();
    }

    const errorBody = await paymentResponse.text();
    this.ngxUiLoader.stop();
    throw new Error(errorBody);
  }

  async tokenize(paymentMethod: any) {
    const tokenResult = await paymentMethod.tokenize();
    if (tokenResult.status === 'OK') {
      return tokenResult.token;
    } else {
      let errorMessage = `Tokenization failed with status: ${tokenResult.status}`;
      if (tokenResult.errors) {
        errorMessage += ` and errors: ${JSON.stringify(
          tokenResult.errors
        )}`;
      }

      throw new Error(errorMessage);
    }
  }

  // status is either SUCCESS or FAILURE;
  displayPaymentResults(status: any) {
    const statusContainer: any = document.getElementById(
      'payment-status-container'
    );
    if (status === 'SUCCESS') {
      statusContainer.classList.remove('is-failure');
      statusContainer.classList.add('is-success');
    } else {
      statusContainer.classList.remove('is-success');
      statusContainer.classList.add('is-failure');
    }

    statusContainer.style.visibility = 'visible';
  }


  async verifyBuyer(payments:any, token:any) {
    const verificationDetails = {
      amount: '1.00',
      billingContact: {
        addressLines: ['123 Main Street', 'Apartment 1'],
        familyName: 'Doe',
        givenName: 'John',
        email: 'jondoe@gmail.com',
        country: 'GB',
        phone: '3214563987',
        region: 'LND',
        city: 'London',
      },
      currencyCode: 'GBP',
      intent: 'CHARGE',
    };

    const verificationResults = await payments.verifyBuyer(
      token,
      verificationDetails
    );
    return verificationResults.token;
  }
}
