<div class="vbogs-content-wrapper vbogs-clearfix vbogs-content-wrapper-full-width-unboxed">
    <!-- BEGIN .vbogs-main-content -->
    <div class="vbogs-main-content vbogs-main-content-full-width-unboxed">
        <div
            class="vc_row wpb_row vc_row-fluid shb-accommodation-single-header-wrapper vc_custom_1627561827928 vc_row-has-fill">
            <div class="wpb_column vc_column_container vc_col-sm-12 vc_col-has-fill" *ngIf="ground.name">
                <div class="vc_column-inner vc_custom_1627561834249">
                    <div class="wpb_wrapper">
                        <h3 class="shb-title-with-icons">{{ground.name}}</h3>
                        <!-- BEGIN .shb-title-with-icons -->
                        <div class="shb-title-with-icons-wrapper clearfix">
                            <span><i class="fas fa-ruler-combined"></i>Size: <span>{{ground.dimentions}}sqm</span></span>
                            <!-- END .shb-title-with-icons-wrapper -->
                        </div>

                        <div class="flexslider">
                            <ul class="slides">
                                <li>
                                    <div class="vbogs-slider-overlay"></div><img [src]="imageBasePath+ground.image"
                                        [alt]="ground.name" draggable="false">
                                </li>
                                <li *ngFor="let i of ground.images.split(',')">
                                    <div class="vbogs-slider-overlay"></div><img [src]="imageBasePath+i"
                                        [alt]="ground.name" draggable="false">
                                </li>
                            </ul>
                        </div>
                        <!-- BEGIN .shb-booking-form-style-1 -->
                        <form class="shb-booking-form-style-1 shb-booking-form-1-column-6 shb-clearfix requestbtn-form">
                            <!-- BEGIN .shb-booking-form-col -->
                            <div class="shb-booking-form-col shb-clearfix requestbtn-cont">
                                <input type="submit" style="width: 250px;" (click)="requestBooking()"
                                    value="Book Now" class="shb-booking-form-button requestbtn">
                                <!-- END .shb-booking-form-col -->
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
        <div
            class="vc_row wpb_row vc_row-fluid shb-accommodation-single-1-wrapper vc_custom_1627561638914 vc_row-has-fill">
            <div class="wpb_column vc_column_container vc_col-sm-12 vc_col-has-fill">
                <div class="vc_column-inner vc_custom_1627561647148">
                    <div class="wpb_wrapper">
                        <div class="vc_empty_space" style="height: 80px"><span class="vc_empty_space_inner"></span>
                        </div>
                        <h3 class="vbogs-title-25px vbogs-clearfix vbogs-title-center">Ground Description</h3>
                        <div class="wpb_text_column wpb_content_element ">
                            <div class="wpb_wrapper" [innerHTML]="ground.description"></div>
                        </div>
                        <div class="vc_empty_space" style="height: 30px"><span class="vc_empty_space_inner"></span>
                        </div>
                        <h3 class="vbogs-title-25px vbogs-clearfix vbogs-title-center">Facilities</h3>
                        <div class="wpb_text_column wpb_content_element ">
                            <div class="wpb_wrapper">
                                <table>
                                    <tbody>
                                        <tr *ngFor="let facility of ground.amenities">
                                            <td><i [ngClass]="facility.icon"></i>{{facility.name}}</td>
                                        </tr>
                                    </tbody>
                                </table>

                            </div>
                        </div>
                        <h3  class="vbogs-title-25px vbogs-clearfix vbogs-title-center">Booking
                            Policy</h3>
                        <div 
                            class="vc_toggle vc_toggle_default vc_toggle_color_default vc_toggle_size_md" [ngClass]="isOpen=='flight'?'vc_toggle_active':''">
                            <div  class="vc_toggle_title">
                                <h4 (click)="openqa('flight')">My flight arrives early in the morning, what time can I check
                                    in?</h4><i  class="vc_toggle_icon"></i>
                            </div>
                            <div class="vc_toggle_content" *ngIf="isOpen=='flight'">
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                                    Praesent et rhoncus neque. Maecenas enim nunc, dapibus in mattis eleifend, luctus et
                                    magna. Maecenas nunc est, posuere sed convallis tincidunt</p>
                            </div>
                        </div>
                        <div 
                            class="vc_toggle vc_toggle_default vc_toggle_color_default vc_toggle_size_md" [ngClass]="isOpen=='break'?'vc_toggle_active':''">
                            <div class="vc_toggle_title">
                                <h4 (click)="openqa('break')">Is breakfast included as standard with all rooms?</h4><i
                                     class="vc_toggle_icon"></i>
                            </div>
                            <div class="vc_toggle_content" *ngIf="isOpen=='break'">
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                                    Praesent et rhoncus neque. Maecenas enim nunc, dapibus in mattis eleifend, luctus et
                                    magna. Maecenas nunc est, posuere sed convallis tincidunt</p>
                            </div>
                        </div>
                        <div 
                            class="vc_toggle vc_toggle_default vc_toggle_color_default vc_toggle_size_md" [ngClass]="isOpen=='child'?'vc_toggle_active':''">
                            <div class="vc_toggle_title">
                                <h4 (click)="openqa('child')">Do you provide a child day care service?</h4><i
                                     class="vc_toggle_icon"></i>
                            </div>
                            <div class="vc_toggle_content" *ngIf="isOpen=='child'">
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                                    Praesent et rhoncus neque. Maecenas enim nunc, dapibus in mattis eleifend, luctus et
                                    magna. Maecenas nunc est, posuere sed convallis tincidunt</p>
                            </div>
                        </div>
                        <div  class="vc_empty_space" style="height: 40px;"><span
                                 class="vc_empty_space_inner"></span></div>
                        <div class="vc_empty_space" style="height: 40px"><span class="vc_empty_space_inner"></span>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- END .vbogs-main-content -->
    </div>

    <!-- END .vbogs-content-wrapper -->
</div>