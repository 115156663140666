<!-- BEGIN .vbogs-footer-wrapper -->
<footer class="vbogs-footer-wrapper">



  <!-- BEGIN .vbogs-footer -->
  <div class="vbogs-footer vbogs-footer-3-col vbogs-clearfix">

    <div id="vbogs_social_about_widget-1"
      class="vbogs-widget vbogs-widget-wrapper vbogs-clearfix widget_vbogs_social_about_widget">
      <h5>Follow Us</h5>
      <div class="vbogs-footer-social-icons-wrapper">

        <p>And keep up to date with VBogs Hotel</p>
        <a target="_blank" href="https://www.facebook.com/vbogsresorts?mibextid=LQQJ4d"><i
            class="fab fa-facebook-f"></i></a>
        <!-- <a target="_blank" href="##"><i class="fab fa-twitter"></i></a> -->
        <a target="_blank" href="https://www.instagram.com/p/CnWVCAAuX0e/?igshid=MDJmNzVkMjY="><i class="fab fa-instagram"></i></a>
        <!-- <a target="_blank" href="##"><i class="fab fa-youtube"></i></a>
        <a target="_blank" href="##"><i class="fab fa-tripadvisor"></i></a> -->
      </div>

    </div>
    <div id="vbogs_contact_widget-1"
      class="vbogs-widget vbogs-widget-wrapper vbogs-clearfix widget_vbogs_contact_widget">
      <h5>Contact</h5>
      <ul class="vbogs-contact-widget">
        <li class="vbogs-address">7488 TX-24, Commerce, TX, United States, Texas</li>
        <li class="vbogs-phone">+1 952-838-5687</li>
        <li class="vbogs-email">vj@vbogs.com</li>
      </ul>

    </div>
    <!-- END .vbogs-footer -->
  </div>
  <!-- BEGIN .vbogs-footer-bottom-wrapper -->
  <div class="vbogs-footer-bottom-wrapper">
    <!-- BEGIN .vbogs-footer-bottom -->
    <div class="vbogs-footer-bottom vbogs-clearfix">
      <p class="vbogs-footer-message">© 2022 VBogs Hotel. All Rights Reserved</p>
    </div>
    <!-- END .vbogs-footer-bottom-wrapper -->
  </div>
  <!-- END .vbogs-footer-wrapper -->
</footer>
<!-- END .vbogs-site-wrapper -->