<div class="vc_row wpb_row vc_row-fluid vc_custom_1626786421882 vc_row-has-fill">
    <div class="wpb_column vc_column_container vc_col-sm-12 vc_col-has-fill">
      <div class="vc_column-inner vc_custom_1626786371291">
        <div class="wpb_wrapper">
          <h3 class="vbogs-title-28px vbogs-clearfix vbogs-title-center">Special Offers</h3>
          <!-- BEGIN .vbogs-blog-carousel-wrapper -->
          <section class="vbogs-blog-carousel-wrapper vbogs-offer-carousel-wrapper vbogs-clearfix">
            <!-- BEGIN .vbogs-blog-carousel -->
            <div class="vbogs-blog-carousel vbogs-owl-carousel-5 owl-carousel owl-theme owl-loaded">
              <!-- END .vbogs-blog-carousel -->
              <div *ngFor="let specialOffer of specialOffersList" class="item">
                <div class="vbogs-blog-block">
                  <a [href]="specialOffer.offerlink"
                    rel="bookmark" title="Summer Couples Offer" class="vbogs-blog-block-image">
                    <img [src]="imageBasePath+specialOffer.image" [alt]="specialOffer.title"> </a>
                  <h4><a [href]="specialOffer.offerlink"
                      _blank rel="bookmark" [title]="specialOffer.title">{{specialOffer.title}}</a></h4>
                  <p class="vbogs-blog-date"><i class="fas fa-tag"></i>{{specialOffer.offerlinktitle}}
                  </p>
                  <!-- END .vbogs-news-block -->
                </div>
              </div>
            </div>
            <!-- END .vbogs-blog-carousel-wrapper -->
          </section>
        </div>
      </div>
    </div>
</div>