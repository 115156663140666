import { Component, OnInit, Input } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-room',
  templateUrl: './room.component.html',
  styleUrls: ['./room.component.scss']
})
export class RoomComponent implements OnInit {
  @Input('hotel') hotel: any;
  imageBasePath = environment.imageBasePath;
  constructor() { }

  ngOnInit(): void {
    console.log(this.hotel);
  }

}
