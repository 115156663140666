<div class="vbogs-page-header"
    style="background-image:url('../../assets/images/08-6.jpg');">
    <h1>Events</h1>
    <!-- END .vbogs-page-header -->
</div>
<div class="vbogs-content-wrapper vbogs-clearfix vbogs-content-wrapper-full-width">

    <!-- BEGIN .vbogs-main-content -->
    <div class="vbogs-main-content vbogs-main-content-full-width">

        <div class="vc_row wpb_row vc_row-fluid">
            <div class="wpb_column vc_column_container vc_col-sm-12">
                <div class="vc_column-inner">
                    <div class="wpb_wrapper">
                        <!-- BEGIN .vbogs-blog-wrapper-1 -->
                        <div class="vbogs-clearboth"></div>
                        <div class="vbogs-blog-wrapper-1">
                            <!-- BEGIN .vbogs-blog-block -->
                            <div *ngFor="let event of events"
                                class="vbogs-blog-block vbogs-clearfix post-2113 post type-post status-publish format-standard has-post-thumbnail hentry category-city-breaks tag-accommodation">
                                <div class="vbogs-blog-block-image">
                                    <a [href]="'events/'+event.id"
                                        rel="bookmark" [title]="event.eventTitle">
                                        <img [src]="imageBasePath+event.image"
                                            alt=""> </a>
                                </div>
                                <!-- BEGIN .vbogs-blog-title-meta-wrapper -->
                                <div class="vbogs-blog-title-meta-wrapper">

                                    <h3><a [href]="'events/'+event.id"
                                            rel="bookmark"
                                            [title]="event.eventTitle">{{event.eventTitle}}</a></h3>

                                    <!-- BEGIN .vbogs-blog-meta -->
                                    <div class="vbogs-blog-meta clearfix">
                                        <span class="vbogs-blog-meta-author"><i class="fas fa-calendar-day"></i><a
                                                [href]="'events/'+event.id"
                                                rel="bookmark"
                                                [title]="event.eventTitle">{{event.eventDate |date:'MMMM d, y'}}</a></span>
                                        <span class="vbogs-blog-meta-author"><i class="fas fa-pencil-alt"></i>By <a
                                                [href]="'events/'+event.id"
                                                title="Posts by admin2021" rel="author">{{event.eventBy}}</a></span>


                                        <span class="vbogs-blog-meta-category"><i class="fas fa-tag"></i><a
                                                [href]="'events/'+event.id"
                                                rel="category tag">{{event.eventType}}</a></span>

                                        <!-- END .vbogs-blog-meta -->
                                    </div>

                                    <!-- END .vbogs-blog-title-meta-wrapper -->
                                </div>


                                <!-- BEGIN .vbogs-blog-description -->
                                <div class="vbogs-blog-description vbogs-clearfix">
                                    <div [innerHTML]="event.smallDescription"></div>
                                    <div class="vbogs-clearboth"></div>
                                    <a [href]="'events/'+event.id"
                                        class="vbogs-more-link">Read More</a>


                                    <!-- END .vbogs-blog-description -->
                                </div>


                                <!-- END .vbogs-blog-block -->
                            </div>
                            <!-- END .vbogs-blog-wrapper -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- END .vbogs-main-content -->
    </div>
    <!-- END .vbogs-content-wrapper -->
</div>