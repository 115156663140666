<div class="shb-accommodation-listing-item">

    <a href="/venues/{{hotel.id}}/{{hotel.hotelurl}}"
      rel="bookmark" title="{{hotel.name}}">
      <img class="shb-accommodation-listing-image" [src]="imageBasePath+hotel.hotelimage"
        alt="{{hotel.name}}"> </a>

    <div class="shb-accommodation-listing-description-wrapper">

      <h3><a
          href="/venues/{{hotel.id}}/{{hotel.hotelurl}}"
          rel="bookmark" title="{{hotel.name}}" class="hotel-title">{{hotel.name}}</a></h3>

      <div class="shb-accommodation-listing-description shb-clearfix">

        <ul class="hotel-detail">
          <li><i class="fas fa-bed"></i><span>Bed Rooms:</span> {{hotel.beds}}</li>
          <li><i class="fas fa-ruler-combined"></i><span>Size:</span> {{hotel.dimentions}}sqm</li>
          <li><i class="fas fa-binoculars"></i><span>View:</span> City</li>
        </ul>
        <a  href="/booking/{{hotel.id}}"class="shb-accommodation-listing-button1">Request Booking</a>
        <a href="/venues/{{hotel.id}}/{{hotel.hotelurl}}"
          class="shb-accommodation-listing-button2">View Venue<i
            class="fas fa-chevron-right"></i></a>
      </div>
    </div>
    <!-- END .shb-accommodation-listing-item -->
  </div>