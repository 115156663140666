import { Component, OnInit } from '@angular/core';
import { AppService } from '../shared/app-service.service';

@Component({
  selector: 'app-hotels',
  templateUrl: './hotels.component.html',
  styleUrls: ['./hotels.component.scss']
})
export class HotelsComponent implements OnInit {

  hotels:any = [];
  isOpen = '';
  constructor(private _AppService:AppService) { }

  ngOnInit(): void {
    this.getHotels();
  }

  getHotels() {
    this._AppService.gethotelsList().subscribe((res:any)=>{
      this.hotels = res;
    })
  }

  openqa(type:any) {
    if(this.isOpen == type) {
      this.isOpen = '';
    } else {
      this.isOpen = type;
    }
  }

}
