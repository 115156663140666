<!-- BEGIN .vbogs-fixed-navigation-wrapper -->
<div class="vbogs-fixed-navigation-wrapper">

  <!-- BEGIN .vbogs-header -->
  <div class="vbogs-header vbogs-fixed-navigation">
    <!-- BEGIN .vbogs-topbar-wrapper -->
    <div class="vbogs-topbar-wrapper vbogs-clearfix">
      <!-- BEGIN .vbogs-topbar -->
      <div class="vbogs-topbar vbogs-clearfix">
        <!-- BEGIN .vbogs-top-left-wrapper -->
        <ul class="vbogs-top-left-wrapper vbogs-clearfix">
          <li class="vbogs-phone-icon"><a href="tel:+1 952-838-5687">+1 952-838-5687</a></li>
          <li class="vbogs-map-icon"><a target="_blank"
              href="https://www.google.com/maps/place/33%C2%B017'10.0%22N+95%C2%B052'20.3%22W/@33.2861081,-95.8728432,19z/data=!3m1!4b1!4m14!1m7!3m6!1s0x864bc83b3473822f:0x1b8f5491bd7736ba!2s7488+TX-24,+Commerce,+TX+75428,+USA!3b1!8m2!3d33.288513!4d-95.8706909!3m5!1s0x0:0x9cdb84b8597769e!7e2!8m2!3d33.2861074!4d-95.8722964">7488
              TX-24, Commerce, TX, United States, Texas</a></li>
          <!-- END .vbogs-top-left-wrapper -->
        </ul>
      </div>
      <!-- END .vbogs-topbar-wrapper -->
    </div>
    <!-- BEGIN .vbogs-logo-navigation -->
    <div class="vbogs-logo-navigation vbogs-clearfix">
      <h2 class="vbogs-logo"><a href="/home" title="vbogs site"><img class="logo"
            src="../../../assets/images/logo.png" /></a></h2>
      <!-- BEGIN .vbogs-navigation -->
      <div class="vbogs-navigation">
        <ul>
          <li id="menu-item-2166"
            class="menu-item menu-item-type-post_type menu-item-object-page menu-item-home current-menu-item page_item page-item-2099 current_page_item current-menu-ancestor current-menu-parent current_page_parent current_page_ancestor  menu-item-2166">
            <a routerLink="/home" routerLinkActive="active" aria-current="page">Home</a>
          </li>
          <li class="menu-item menu-item-type-post_type menu-item-object-page"><a routerLink="/venues"
              routerLinkActive="active">Venues</a>
          </li>
          <li class="menu-item menu-item-type-post_type menu-item-object-page"><a routerLink="/events"
              routerLinkActive="active">Events</a>
          </li>
          <li class="menu-item menu-item-type-post_type menu-item-object-page"><a routerLink="/grounds"
              routerLinkActive="active">Sports Academy</a>
          </li>
          <li class="menu-item menu-item-type-post_type menu-item-object-page"><a routerLink="/series"
              routerLinkActive="active">Cricket Tournaments</a>
          </li>
          <li class="menu-item menu-item-type-post_type menu-item-object-page"><a routerLink="/blogs"
              routerLinkActive="active">Blogs</a>
          </li>
          <li class="menu-item menu-item-type-post_type menu-item-object-page"><a routerLink="/aboutus"
              routerLinkActive="active">About Us</a>
          </li>
          <li class="menu-item menu-item-type-post_type menu-item-object-page"><a routerLink="/contactus"
              routerLinkActive="active">Contact Us</a>
          </li>
        </ul>
        <!-- END .vbogs-navigation -->
      </div>


      <a class="vbogs-mobile-navigation-button"><i class="fas fa-bars"
          (click)="isShowMobileMenu = !isShowMobileMenu"></i></a>

      <!-- END .vbogs-logo-navigation -->
    </div>

    <!-- BEGIN .vbogs-mobile-navigation-wrapper -->
    <div class="vbogs-mobile-navigation-wrapper vbogs-clearfix" [ngStyle]="{'display':isShowMobileMenu?'block':'none'}">

      <ul class="vbogs-mobile-navigation" (click)="isShowMobileMenu = !isShowMobileMenu">
        <li id="menu-item-2166"
          class="menu-item menu-item-type-post_type menu-item-object-page menu-item-home current-menu-item page_item page-item-2099 current_page_item current-menu-ancestor current-menu-parent current_page_parent current_page_ancestor  menu-item-2166">
          <a routerLink="/home" routerLinkActive="active" aria-current="page">Home</a>
        </li>
        <li class="menu-item menu-item-type-post_type menu-item-object-page"><a routerLink="/venues"
            routerLinkActive="active">Venues</a>
        </li>
        <li class="menu-item menu-item-type-post_type menu-item-object-page"><a routerLink="/events"
            routerLinkActive="active">Events</a>
        </li>
        <li class="menu-item menu-item-type-post_type menu-item-object-page"><a routerLink="/grounds"
            routerLinkActive="active">Sports Academy</a>
        </li>
        <li class="menu-item menu-item-type-post_type menu-item-object-page"><a routerLink="/series"
            routerLinkActive="active">Cricket Tournaments</a>
        </li>
        <li class="menu-item menu-item-type-post_type menu-item-object-page"><a routerLink="/blogs"
            routerLinkActive="active">Blogs</a>
        </li>
        <li class="menu-item menu-item-type-post_type menu-item-object-page"><a routerLink="/aboutus"
            routerLinkActive="active">About Us</a>
        </li>
        <li class="menu-item menu-item-type-post_type menu-item-object-page"><a routerLink="/contactus"
            routerLinkActive="active">Contact Us</a>
        </li>
      </ul>
    </div>
    <!-- END .vbogs-header -->
  </div>
  <!-- END .vbogs-fixed-navigation-wrapper -->
</div>
<!-- BEGIN .vbogs-content-wrapper -->