<div class="vbogs-page-header"
    style="background-image:url('../../assets/images/08-6.jpg');">

    <h1>About Us</h1>

    <!-- END .vbogs-page-header -->
</div>
<div class="vbogs-content-wrapper vbogs-clearfix vbogs-content-wrapper-full-width">

    <!-- BEGIN .vbogs-main-content -->
    <div class="vbogs-main-content vbogs-main-content-full-width">

        <div class="vc_row wpb_row vc_row-fluid vc_custom_1627562291998 vbogs-content-wrapper-vc vc_row-has-fill">
            <div class="wpb_column vc_column_container vc_col-sm-12 vc_col-has-fill">
                <div class="vc_column-inner vc_custom_1627562322556">
                    <div class="wpb_wrapper">
                        <div class="vbogs-fixed-height-text-image-wrapper vbogs-clearfix">
                            <div class="vbogs-fixed-height-text-wrapper vbogs-fixed-height-text-wrapper-left">
                                <h3 class="vbogs-title-28px vbogs-clearfix vbogs-title-left">Our Hotel</h3>
                                <p></p>
                                <p>Nullam tincidunt tellus vel nibh facilisis, in vestibulum metus volutpat. Suspendisse
                                    vestibulum, purus pulvinar commodo commodo, sem nisi luctus tellus, nec suscipit
                                    purus nisi vel tellus.</p>
                                <p>Ut eget eros cursus augue gravida imperdiet. Sed vitae lobortis tortor. In justo
                                    eros, blandit ac dapibus vitae, gravida molestie enim. Praesent dictum ligula vitae.
                                </p>
                                <p></p>
                            </div>
                            <div class="vbogs-fixed-height-image-wrapper vbogs-fixed-height-image-wrapper-right">
                                <div class="vbogs-fixed-height-image"
                                    style="background-image: url(http://themes.quitenicestuff2.com/sohohotel/demo1/wp-content/uploads/2021/09/13-6.jpg); background-size: 600px 400px; height: 400px;">
                                </div>
                            </div>
                        </div>
                        <div class="vbogs-fixed-height-text-image-wrapper vbogs-clearfix">
                            <div class="vbogs-fixed-height-text-wrapper vbogs-fixed-height-text-wrapper-right">
                                <h3 class="vbogs-title-28px vbogs-clearfix vbogs-title-left">The Location</h3>
                                <p></p>
                                <p>Nullam tincidunt tellus vel nibh facilisis, in vestibulum metus volutpat. Suspendisse
                                    vestibulum, purus pulvinar commodo commodo, sem nisi luctus tellus, nec suscipit
                                    purus nisi vel tellus.</p>
                                <p>Ut eget eros cursus augue gravida imperdiet. Sed vitae lobortis tortor. In justo
                                    eros, blandit ac dapibus vitae, gravida molestie enim. Praesent dictum ligula vitae.
                                </p>
                                <p></p>
                            </div>
                            <div class="vbogs-fixed-height-image-wrapper vbogs-fixed-height-image-wrapper-left">
                                <div class="vbogs-fixed-height-image"
                                    style="background-image: url(http://themes.quitenicestuff2.com/sohohotel/demo1/wp-content/uploads/2021/09/14-6.jpg); background-size: 600px 400px; height: 400px;">
                                </div>
                            </div>
                        </div>
                        <div class="vbogs-fixed-height-text-image-wrapper vbogs-clearfix">
                            <div class="vbogs-fixed-height-text-wrapper vbogs-fixed-height-text-wrapper-left">
                                <h3 class="vbogs-title-28px vbogs-clearfix vbogs-title-left">Hotel Services</h3>
                                <p></p>
                                <p>Nullam tincidunt tellus vel nibh facilisis, in vestibulum metus volutpat. Suspendisse
                                    vestibulum, purus pulvinar commodo commodo, sem nisi luctus tellus, nec suscipit
                                    purus nisi vel tellus.</p>
                                <p>Ut eget eros cursus augue gravida imperdiet. Sed vitae lobortis tortor. In justo
                                    eros, blandit ac dapibus vitae, gravida molestie enim. Praesent dictum ligula vitae.
                                </p>
                                <p></p>
                            </div>
                            <div class="vbogs-fixed-height-image-wrapper vbogs-fixed-height-image-wrapper-right">
                                <div class="vbogs-fixed-height-image"
                                    style="background-image: url(http://themes.quitenicestuff2.com/sohohotel/demo1/wp-content/uploads/2021/09/15-6.jpg); background-size: 600px 400px; height: 400px;">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="vc_row wpb_row vc_row-fluid vc_custom_1627553435640 vc_row-has-fill">
            <div class="wpb_column vc_column_container vc_col-sm-12 vc_col-has-fill">
                <div class="vc_column-inner vc_custom_1627553414681">
                    <div class="wpb_wrapper">
                        <div
                            class="vc_separator wpb_content_element vc_separator_align_center vc_sep_width_100 vc_sep_pos_align_center vc_separator_no_text">
                            <span class="vc_sep_holder vc_sep_holder_l"><span style="border-color:#dedede;"
                                    class="vc_sep_line"></span></span><span class="vc_sep_holder vc_sep_holder_r"><span
                                    style="border-color:#dedede;" class="vc_sep_line"></span></span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <app-hotel-facilities></app-hotel-facilities>


        <!-- END .vbogs-main-content -->
    </div>


    <!-- END .vbogs-content-wrapper -->
</div>